var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-container page"},[_c('img',{staticClass:"bg-img",attrs:{"src":"img/login/login-bg.png"}}),_c('div',{staticClass:"bg-wrapper"},[_c('div',{staticClass:"register"},[_c('van-nav-bar',{staticClass:"nav-bar",scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left","color":"#fff"},on:{"click":function($event){return _vm.back()}}})]},proxy:true}])}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"logo-container"},[_c('div',{staticClass:"logo-wrapper"},[_c('img',{staticClass:"logo-img",attrs:{"src":this.$store.getters.getBaseInfo.ico !== undefined
                  ? this.$store.getters.getBaseInfo.ico
                  : ''}})])]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(`Page['注册']`)))]),_c('div',{staticClass:"loginForm"},[_c('van-field',{staticClass:"input",attrs:{"clearable":"","input-align":"center","placeholder":`${_vm.$t(`Page['请输入用户名']`)} (${_vm.$t(
              `Page['6-12个英文字母或数字']`,
            )})`},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('van-field',{staticClass:"input",attrs:{"type":_vm.passwordType,"input-align":"center","placeholder":`${_vm.$t(`Page['请输入用户名']`)} (${_vm.$t(
              `Page['6-12个英文字母或数字']`,
            )})`},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('template',{slot:"right-icon"},[_c('van-icon',{attrs:{"name":_vm.passwordType === 'password' ? 'closed-eye' : 'eye-o'},on:{"click":_vm.switchPasswordType}})],1)],2),_c('van-field',{staticClass:"input",attrs:{"clearable":"","input-align":"center","placeholder":_vm.$t(`Page['请输入邀请码']`)},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('div',{staticClass:"agreement"},[_c('van-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}),_c('span',{staticClass:"agreement-text"},[_vm._v(_vm._s(_vm.$t(`Page['我已经知晓并同意开户协议各项条约']`)))])],1),_c('van-button',{staticClass:"login-btn",attrs:{"type":"primary","size":"normal"},on:{"click":function($event){return _vm.doRegister()}}},[_vm._v(" "+_vm._s(_vm.$t(`Page['注册']`)))])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }