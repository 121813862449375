<template>
  <div class="page_home">
    <van-nav-bar
      :title="$t(`Page['P9财富']`)"
      border
      fixed
      placeholder />
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh">
      <div class="banner_box">
        <van-swipe :autoplay="3000">
          <van-swipe-item
            v-for="(item, index) in banners"
            :key="index">
            <van-image
              lazy-load
              :src="item.url"
              height="140px"
              width="100vw"
              fit="cover"></van-image>
          </van-swipe-item>
        </van-swipe>
      </div>
      <van-notice-bar
        left-icon="volume-o"
        :text="notice" />
      <div class="home_content">
        <div class="home_title">
          <van-divider dashed>{{ $t(`Page['热门专区']`) }}</van-divider>
        </div>
        <van-grid
          gutter="10"
          square
          :border="true"
          :column-num="2">
          <van-grid-item
            @click="toLottery(item.key, item.id)"
            v-for="(item, key) in gameitem"
            :key="key">
            <van-image
              width="120px"
              height="120px"
              lazy-load
              :src="item.ico" />
            <div class="grid_text">
              {{ item.name }}
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        notice: `${this.$t(`Page['获取中']`)}...`,
        banners: [{}],
        basicData: [],
        gameitem: [{}, {}, {}, {}],
        movielist_0: [{}, {}, {}, {}],
        movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
        isLoading: false,
        movielistSwiperOption: {
          slidesPerView: "auto",
          spaceBetween: 0,
          slidesPerGroup: 1,
        },
        bannerSwiperOption: {
          effect: "coverflow",
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          speed: 800,
          autoplay: true,
          coverflowEffect: {
            rotate: 50,
            stretch: 10,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
        },
      };
    },
    methods: {
      gotoMenu(router) {
        this.$router.replace(router);
      },
      toLottery(key, id) {
        if (!localStorage.getItem("token")) {
          this.$router.push({ path: "/Login" });
        } else {
          this.$router.push({ path: "/Game?key=" + key + "&id=" + id });
        }
      },
      toPlayVideo(id) {
        if (!localStorage.getItem("token")) {
          this.$router.push({ path: "/Login" });
        } else {
          this.$router.push({ path: "/PlayVideo?id=" + id });
        }
      },
      onRefresh() {
        setTimeout(() => {
          this.getBasicConfig();
          this.isLoading = false;
          this.$toast(this.$t(`Page['刷新成功']`));
        }, 500);
      },
      getBasicConfig() {
        this.$http({
          method: "get",
          url: "sys_config",
        }).then((res) => {
          this.basicData = res.data;
          this.getNotice(this.basicData); //获取公告
          this.getBanner(this.basicData); //获取banner
          this.getGameItem(); //获取首页游戏列表
          this.getMovieList_0(this.basicData); //获取首页视频0
          this.getMovieList_1(this.basicData); //获取首页视频1
        });
      },
      getNotice(data) {
        this.notice = data.notice;
      },
      getGameItem() {
        this.$http({
          method: "get",
          url: "lottery_hot",
        }).then((res) => {
          this.gameitem = res.data;
        });
      },
      getMovieList_0(data) {
        this.movielist_0 = data.movielist_0;
      },
      getMovieList_1(data) {
        this.movielist_1 = data.movielist_1;
      },
      getBanner(data) {
        this.banners = data.banners;
      },
    },
    mounted() {
      this.getBasicConfig();
    },
  };
</script>

<style lang="less">
  .page_home {
    min-height: calc(100vh - 50px - constant(safe-area-inset-bottom));
    min-height: calc(100vh - 50px - env(safe-area-inset-bottom));
    background-color: #f5f5f5;
    .van-nav-bar {
      background: linear-gradient(220.55deg, #3793ff 0%, #0017e4 100%);
      .van-nav-bar__title {
        color: #fff;
      }
    }
    .van-pull-refresh {
      min-height: calc(100vh - 96px - constant(safe-area-inset-bottom));
      min-height: calc(100vh - 96px - env(safe-area-inset-bottom));
    }
    .banner_box {
      height: 140px;
    }
    .home_content {
      .home_title {
        padding: 0 12px;
        .van-divider {
          font-size: 16px;
          color: #333;
          font-weight: 500;
          border-color: #999;
        }
      }
      .grid_text {
        font-size: 14px;
        padding-top: 8px;
        color: #333;
      }
    }
  }
</style>
